import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";

import "../css/rahul-styles.css";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

import PopupForm from "../components/popupForm";
import MainLayout from "../components/layout";

import BlogListingCard from '../components/blog-listing-card';
import TopArticles from '../components/top-article';
import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

let selectedCategoryName = "All Blogs";

const StudyResourceArchive = ({ data, pageContext,path }) => {
  const [collapsed, setCollapsed] = useState(false);
 
  const [time, setTime] = useState(900000000);
  
  const blogs = data.allWordpressWpBlog.edges;
  const blogTop = data.allWordpressPage.nodes[0];
  const topArticlesData = data.allWordpressPage.nodes[0].acf.top_articles;
  const allPosts = data.allWordpressWpBlog.edges;
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "/study-resources/" : `/study-resources/${currentPage - 1}/`;
  const nextPage = `/study-resources/${currentPage + 1}/`;
  const post = data.allWordpressPage.nodes[0];
  const metatitle = "Study Abroad Blogs and Resources | Page " + currentPage + " | The WorldGrad";
  let metadescription;

  if (currentPage === 1) {
  metadescription = "Discover study abroad guides and resources. Explore international education trends & top universities in Australia, UK, US & Singapore.";

} else {
  metadescription = `Discover study abroad guides and resources on Page ${currentPage}. Explore international education trends & top universities in Australia, UK, US & Singapore.`;

}



  const focus_keyphrase = post.acf.focus_keyphrase;

  const canonical_url = `https://theworldgrad.com${path}`; 


  const filterCategoryMobile = (event) => {
    const selectedCategory = event.target.value;
    //console.log('selectedCategory->' + selectedCategory);
  
    if (selectedCategory === 'news') {
      window.location.href = '/news/';
    } else if (selectedCategory === 'All') {
      window.location.href = '/study-resources/';
    } else {
      window.location.href = `/study-resources/${selectedCategory}/`;
    }
  };
  const handlePopupWindow = () => {
    this.setState({ time: Math.random() });
  };

  return (
    <div>
      <MainLayout>
        <Helmet>
          <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {focus_keyphrase && <meta name="keywords" content={focus_keyphrase} />}
          <link rel="canonical" href={canonical_url} />
          {post.acf.dynamic_schema && (
            <script type="application/ld+json">{JSON.stringify(JSON.parse(post.acf.dynamic_schema))}</script>
          )}
          
        </Helmet>
        {/*canonical_url*/}
        <div className="newStyle">
          <div className="container">
            <ol className="transparentbreadcrumb breadcrumb breadcrumb-new">
              <li className="breadcrumb-item">
                <a href="/">Home </a>
              </li>
              <li className="breadcrumb-item active"> Study Resources</li>
            </ol>
          </div>
        </div>
        <div className="container" style={{ overflowX: "hidden" }}>
          <PopupForm time={time} />
          <div className="col-12">
            <button
              className="btn fixed-enquire-button"
              onClick={handlePopupWindow}>
              Enquire
            </button>
          </div>
          <div className="my-1">
            <div className="row newStyle p-0">
              <div className="col-12">
                <h1 className="text-muted mb-1">{blogTop.title}</h1>
                <p className="mb-lg-5 text-muted" dangerouslySetInnerHTML={{ __html: blogTop.content }}></p>
                <h2 className="blog-listing-sub-heading">Top Articles this Month</h2>
                </div>
                <TopArticles/>
              </div>
          
              <div className="col-md-12 card">
      <h5 style={{ fontSize: "18px", marginTop: "20px" }} className="margin-left-minus-15">Categories</h5>
      <ul className="category-menu menu-desktop margin-left-minus-15" style={{ listStyleType: "none" }}>
        <li key="1">
          <Link to="/study-resources/" data-value="All">All</Link>
        </li>
        {data.allWordpressWpBlogCategories.edges.map(({ node }) => (
  node.slug !== 'news' && (
    <li key={node.wordpress_id} data-id={node.wordpress_id} data-value={node.name}>
      <Link to={`/study-resources/${node.slug}/`}>
        {ReactHtmlParser(node.name)}
      </Link>
    </li>
  )
))}
      </ul>
      <select className="category-menu menu-mobile margin-left-minus-15" onChange={filterCategoryMobile}>
        <option value="All">All</option>
        {data.allWordpressWpBlogCategories.edges.map(({ node }) => (
          <option key={node.wordpress_id} value={node.slug}>
            {ReactHtmlParser(node.name)}
          </option>
        ))}
      </select>
      <div className="blog-listing-card-sub-heading-container">
        <div className="blog-listing-sub-heading margin-left-minus-15">
          {selectedCategoryName}
        </div>
        <div className="blog-listing-masonry-layout">
          <div className="blog-listing-wrapper">
            {blogs.map(({ node }) => (
              <BlogListingCard key={node.id} post={node} />
            ))}
          </div>
        </div>
        <div className="container bottom-row-blog-listing-container"></div>
      </div>
    </div>
    

          <div className="bottom-row-blog-listing blog-pagination ">
    <div className="col-12 p-0">
      {!isFirst && (
        <Link to={prevPage} rel="prev"  class="blog-pagination-link prev-page-link">
          <img src={ArrowLeftSolidIcon} alt="<-" className="left-icon newicon newicon-black" /> Go to Page {currentPage - 1}
        </Link>
      )}

      {!isLast && (
        <Link to={nextPage} rel="next"  class="blog-pagination-link next-page-link">
          Go to Page {currentPage + 1} <img src={ArrowRightSolidIcon} alt="->" className="right-icon newicon newicon-black" />
        </Link>
      )}
    </div>
  </div>
          </div>
          </div>


      </MainLayout>


    </div>
  );
};

export default StudyResourceArchive;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
     allWordpressWpBlog(
    skip: $skip
    limit: $limit
    filter: { acf: { select_category: { nin: "News" } } }
    sort: { fields: modified, order: DESC }  
  ) {
  
      edges {
        node {
          id
          title
          slug
          blog_categories
          wordpress_id
          date(formatString: "DD-M-YYYY")
          modified(formatString: "DD-M-YYYY")
          acf {
            description
            select_category
            blog_grid_image { 
          source_url
            }
          }
          featured_media {
            source_url
          }
        }
      }
    }
    allWordpressPage(filter: { wordpress_id: { in: 3241 } }) {
      nodes {
        title
        content
        acf {
          top_articles {
            wordpress_id
            post_title
            post_name
            post_date(formatString: "DD-M-YYYY")
          }
          meta_title
          meta_description
          focus_keyphrase
          canonical_url
          dynamic_schema
          blog_categories {
            name
            slug
            term_id
          }
        }
      }
    }
    allWordpressWpBlogCategories {
      edges {
        node {
          id
          name
          wordpress_id
          slug
        }
      }
    }
  }
`;
